.slider-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px 0;
}

.slider-heading {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 15px;
    text-align: center;
    color: #e5dada;
}

.slider-wrapper {
    position: relative;
    width: 80%;
    max-width: 600px;
    overflow: hidden;
    border-radius: 10px;
}

.slider-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
    transition: transform 0.5s ease-in-out;
}

.slider-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 12px;
    cursor: pointer;
    border-radius: 50%;
    transition: background 0.3s ease;
}

.slider-button.left {
    left: 10px;
}

.slider-button.right {
    right: 10px;
}

.slider-button:hover {
    background: rgba(0, 0, 0, 0.8);
}

/* Responsive Design */
@media (max-width: 768px) {
    .slider-wrapper {
        width: 90%;
    }
}

@media (max-width: 480px) {
    .slider-container {
        padding: 10px 0;
    }

    .slider-heading {
        font-size: 20px;
    }

    .slider-button {
        padding: 8px;
    }
}